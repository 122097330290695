import Vue, { createApp, createSSRApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import drapejs from '@drapejs/core';
import { plugin as Litium } from '@motillo/drapejs-litium';
import { OptionsComponent } from '@drapejs/core';
import Worker from 'worker-loader!./web-worker';
import MarkdownIt from 'markdown-it';
import mitt from 'mitt';

import { format, parse } from 'date-fns';
import { dateLocales, buildUrlPathWithQuery } from './utils';

import * as config from './config';

import './style/index.css';

const emitter = mitt();
const tokenRegex = /\{{(.+?)\}}/gi;

function createMd() {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true
  });

  md.renderer.rules.table_open = () => '<table class="markdown-table">\n';

  return md;
}

export default function (workerFactory: () => Worker) {
  let create;
  if (process.env.SSR) {
    create = createSSRApp;
  } else {
    create = createApp;
  }

  const app = create(App)
    .use(drapejs, {
      pageComponents: {
        ContentPage: defineAsyncComponent(() => import('./pages/ContentPage.vue')),
        NotFound: defineAsyncComponent(() => import('./pages/NotFound.vue')),
        Error: defineAsyncComponent(() => import('./pages/Error.vue')),
        Login: defineAsyncComponent(() => import('./pages/Login.vue')),
        Category: defineAsyncComponent(() => import('./pages/Category.vue')),
        OrderDetails: defineAsyncComponent(() => import('./pages/OrderDetails.vue')),
        Product: defineAsyncComponent(() => import('./pages/Product.vue')),
        WholesaleCheckout: defineAsyncComponent(() => import('./pages/Checkout.vue')),
        VingaOfSwedenReceipt: defineAsyncComponent(() => import('./pages/Receipt.vue')),
        BlogIndex: defineAsyncComponent(() => import('./pages/BlogIndex.vue')),
        BlogArticle: defineAsyncComponent(() => import('./pages/BlogArticle.vue')),
        GiftCardEditor: defineAsyncComponent(() => import('./pages/GiftCardEditor.vue'))
      },
      workerFactory
    })
    .use(Litium as Vue.Plugin, {
      baseUrl: config.litiumBaseUrl,
      blockComponents: {
        // Your block component names should match your block IDs in Litium
        HTMLBlock: defineAsyncComponent(() => import('./blocks/Html.vue')),
        TextWithTitle: defineAsyncComponent(() => import('./blocks/TextWithTitle.vue')),
        HeroBlock: defineAsyncComponent(() => import('./blocks/Hero.vue')),
        CenteredText: defineAsyncComponent(() => import('./blocks/CenteredText.vue')),
        MediaBlock: defineAsyncComponent(() => import('./blocks/Media.vue')),
        TitleParagraph: defineAsyncComponent(() => import('./blocks/TitleParagraph.vue')),
        TextWithMedia: defineAsyncComponent(() => import('./blocks/TextWithMedia.vue')),
        LinkBoxes: defineAsyncComponent(() => import('./blocks/LinkBoxes.vue')),
        RelatedLinks: defineAsyncComponent(() => import('./blocks/RelatedLinks.vue')),
        FeaturedPosts: defineAsyncComponent(() => import('./blocks/FeaturedPosts.vue')),
        InfoCards: defineAsyncComponent(() => import('./blocks/InfoCards.vue')),
        ProductBlock: defineAsyncComponent(() => import('./blocks/Product.vue')),
        SplitImageIntro: defineAsyncComponent(() => import('./blocks/SplitImageIntro.vue')),
        Logotypes: defineAsyncComponent(() => import('./blocks/Logotypes.vue')),
        TextWithProduct: defineAsyncComponent(() => import('./blocks/TextWithProduct.vue')),
        TextWith2Images: defineAsyncComponent(() => import('./blocks/TextWith2Images.vue')),
        LinkBoxesWithText: defineAsyncComponent(() => import('./blocks/LinkBoxesWithText.vue')),
        SpacerBlock: defineAsyncComponent(() => import('./blocks/SpacerBlock.vue'))
      }
    })
    .mixin({
      extends: OptionsComponent,
      inject: {
        $cartReactive: {
          default: {}
        },
        $layoutReactive: {
          default: {}
        },
        $channelReactive: {
          default: {}
        },
        $availableChannelsReactive: {
          default: {}
        },
        $categoryTreeReactive: {
          default: {}
        },
        $userReactive: {
          default: {}
        }
      },
      data: () => ({
        width: 0
      }),
      computed: {
        $cart() {
          return (this.$cartReactive && this.$cartReactive?.data) || {};
        },
        $layout() {
          return (this.$layoutReactive && this.$layoutReactive?.data) || {};
        },
        $channel() {
          return (this.$channelReactive && this.$channelReactive?.data) || {};
        },
        $availableChannels() {
          return (this.$availableChannelsReactive && this.$availableChannelsReactive?.data) || [];
        },
        $categoryTree() {
          return (this.$categoryTreeReactive && this.$categoryTreeReactive?.data) || {};
        },
        $globalTexts() {
          return (this.$channelReactive && this.$channelReactive?.data?.website?.websiteTexts) || {};
        },
        $globalFields() {
          return (this.$channelReactive && this.$channelReactive?.data?.website?.fields) || {};
        },
        $user() {
          if (!this.$userReactive) {
            return {};
          }

          const user = { ...(this.$userReactive?.data || {}) };
          user.isAuthenticated = this.$userReactive?.data?.isAuthenticated || false;

          return user;
        },
        $mitt() {
          return emitter;
        },
        $isPhone() {
          return this.width < 768;
        }
      } as any,
      methods: {
        $formatPrice(value: number, decimals?: number, locale?: string, currencyId?: string, formatStyle?: string) {
          if (!decimals) {
            decimals = 2;
          }
          if (value !== 0 && !value) {
            return '';
          }
          if (!this.$cart) {
            return value;
          }
          return new Intl.NumberFormat(locale || this.$channel?.locale || 'sv-SE', {
            style: formatStyle || 'currency',
            currency: currencyId || this.$cart.currency?.id || 'SEK',
            maximumFractionDigits: decimals,
            minimumFractionDigits: 0
          }).format(value);
        },
        $toVariantUrl(variant: {
          url: string;
          fields?: {
            Size?: { name: string; value: string };
            Color?: [{ name: string; value: string }];
            GiftcardType?: string;
          };
        }) {
          function variantQueryParams() {
            let urlSuffix = '';

            const suffixParts = [
              {
                key: 'size',
                value: variant.fields?.Size?.value
              },
              {
                key: 'color',
                value: (variant.fields?.Color || []).map((c: { name: string; value: string }) => c.value).join(',')
              },
              {
                key: 'giftcardType',
                value: variant.fields?.GiftcardType
              }
            ].filter((q) => q.value);

            if (suffixParts.length) {
              urlSuffix = suffixParts
                .reduce((p, { key, value }) => {
                  return `${p}${key}=${value}&`;
                }, '?')
                .slice(0, -1);
            }

            return urlSuffix;
          }

          return variant.url + variantQueryParams();
        },
        $isColorVariantMatch(
          color: string,
          variant: {
            fields: {
              Size?: { name: string; value: string };
              Color?: [{ name: string; value: string }];
            };
          }
        ) {
          let variantColor = variant.fields.Color?.map((c) => c.value) || [];
          let colorCopy = color.split(',');
          if (variantColor.length !== colorCopy.length) return false;

          variantColor.sort();
          colorCopy.sort();

          for (let i = 0; i < variantColor.length; i++) {
            if (variantColor[i] !== colorCopy[i]) return false;
          }

          return true;
        },
        $variantToColorString(variant?: {
          fields: {
            Size?: { name: string; value: string };
            Color?: [{ name: string; value: string }];
          };
        }) {
          const color = variant?.fields?.Color?.map((c) => c.value).sort() || [];
          return color?.join(',');
        },
        $renderMarkdown(text: string) {
          if (!text) {
            return '';
          }

          return createMd().render(text);
        },
        $renderMarkdownInline(text: string) {
          if (!text) {
            return '';
          }

          return createMd().renderInline(text);
        },
        $replaceTokens(text: string, args: any) {
          const replace = (textToFormat: string) => {
            if (!args) {
              args = {};
            }

            return textToFormat.replace(tokenRegex, (_, p1) => args[p1.toLowerCase()] || this.$globalTexts[p1.toLowerCase()] || '');
          };

          return !text ? text : replace(text);
        },
        $formatDate(date: Date, dateFormat: string) {
          const language: Locale = (dateLocales as any)[this.$channel.locale] as Locale;
          return format(date, dateFormat, { locale: language });
        },
        $parseDate(date: string, dateFormat: string) {
          const language: Locale = (dateLocales as any)[this.$channel.locale] as Locale;
          return parse(date, dateFormat, new Date(), { locale: language });
        },
        $toBlock(template: string) {
          if (!template) return '';

          return `block-${template.toLowerCase()}`;
        },
        $navigateToLoginPage(query = null) {
          const search = query || this.$route.query || {};
          const completeQuery = {
            ...search,
            redirect: encodeURIComponent(this.$route.pathname || '')
          };
          this.$getItem('__channel').then((channel: any) => {
            if (channel?.loginPageUrl) {
              const loginUrl = buildUrlPathWithQuery(channel.loginPageUrl, completeQuery);
              this.$navigate(loginUrl);
            } else if (channel?.rootPath) {
              const rootPageUrl = buildUrlPathWithQuery(channel.rootPath, completeQuery);
              this.$navigate(rootPageUrl);
            }
          });
        },
        setWidth() {
          this.width = window?.innerWidth || 0;
        }
      } as any,
      mounted() {
        this.setWidth();
        window.addEventListener('resize', this.setWidth);
      },
      beforeUnmount() {
        window.removeEventListener('resize', this.setWidth);
      }
    });

  app.config.warnHandler = function (msg, vm, trace) {
    console.log(msg, vm, trace);
  };

  return app;
}
